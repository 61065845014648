import { ethers } from 'ethers';

export const findAddress = async (address: string): Promise<string> => {
  const provider = new ethers.providers.JsonRpcProvider(
    `https://${process.env.REACT_APP_NETWORK_NAME}.infura.io/v3/${process.env.REACT_APP_INFURA_API}`,
  );
  if (!address.includes('.')) {
    return address;
  }
  const ethAddress = await provider.resolveName(address);
  if (!ethAddress) return address;
  return ethAddress;
};
