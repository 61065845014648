import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { tokens as tokens_mainnet } from '../whitelist_mainnet.json';
import { tokens as tokens_kovan } from '../whitelist_kovan.json';
import { tokens as tokens_goerli } from '../whitelist_goerli.json';

const POLLING_INTERVAL = 10000;
const chainId = +`${process.env.REACT_APP_NETWORK_CHAINID}`;
// metamask
export const injected = () =>
  new InjectedConnector({
    supportedChainIds: [chainId],
  });

// walletconnector
export const walletconnect = new WalletConnectConnector({
  rpc: {
    [chainId]: `https://${process.env.REACT_APP_NETWORK_NAME}.infura.io/v3/${process.env.REACT_APP_INFURA_API}`,
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  // @ts-ignore
  pollingInterval: POLLING_INTERVAL,
});

export function resetWalletConnector(connector: any) {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
}

export enum Chains {
  'Kovan Testnet' = 42,
  'Ethereum Mainnet' = 1,
  'Goerli Testnet' = 5,
}

let tokensToExport;
switch (process.env.REACT_APP_NETWORK_NAME) {
  case 'mainnet':
    tokensToExport = tokens_mainnet;
    break;
  case 'kovan':
    tokensToExport = tokens_kovan;
    break;
  case 'goerli':
    tokensToExport = tokens_goerli;
    break;
  default:
    tokensToExport = tokens_kovan;
    break;
}
export const tokens = tokensToExport;
