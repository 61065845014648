import styled from 'styled-components';

interface ERC721ItemProps {
  selected?: boolean;
}

export const ERC721ItemWrapper = styled.div<ERC721ItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border-radius: 16px;
  transition: box-shadow 0.2s ease-in-out;
  height: 400px;
  position: relative;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  ${(props) =>
    props.selected
      ? `
          border: 4px solid #18A0FB
          `
      : ''}
`;

export const ERC721Image = styled.img<ERC721ItemProps>`
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 16px 16px 0 0;

  &.no-image {
    width: 70%;
    height: 100%;
    max-height: 302px;
  }

  ${(props) =>
    props.selected
      ? `
          border-radius: 12px 12px 0 0;
          `
      : ''}
`;

export const ERC721Content = styled.div`
  width: 100%;
  height: 100px;
  padding: 8px 16px;
  position: relative;

  .helpModal {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: #000000;
    color: #fff;
    height: 105px;
    width: 232px;
    bottom: 76px;
    padding-left: 8px;
    border-radius: 8px;
    transition: visibility 0.2s linear, opacity 0.2s linear;
    z-index: 99;
    cursor: auto;

    &__link {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      background: linear-gradient(180deg, #566ffe 0%, #18a0fb 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      //background-clip: text;
      text-fill-color: transparent;
      text-decoration: none;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  .helpModal::after {
    content: '';
    position: relative;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    bottom: -56px;
    right: 200px;
  }
`;

export const ERC721Title = styled.h5`
  color: #183b56;
  font-weight: 600;
  font-size: 1rem;
  margin: 4px 0px;
`;

export const ERC721Numbering = styled.span`
  font-size: 0.875rem;
  color: #183b56;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VerifyIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const RefreshMetadataModal = styled.div`
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear, background-color 0.2s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 40px;
  right: -30px;
  bottom: 20px;
  border-radius: 20px;
  border: 0px solid #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: #183b56;
  font-size: 0.7rem;
  font-weight: 500;
  background-color: white;

  &:hover {
    background-color: #f8f8f8;
  }

  img {
    width: 15%;
    height: 30%;
  }
`;

export const ThreeDots = styled.div`
  position: relative;
  margin-top: 2.25rem;

  &:hover {
    ${RefreshMetadataModal} {
      visibility: visible;
      opacity: 1;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 30px;
    border-bottom: 8px dotted #c8c8c8;
    position: absolute;
    right: 0px;
    bottom: 5px;
  }
`;
