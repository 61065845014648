import axios from 'axios';

import {
  GetTokensFromId,
  GetTokensFromId_swaps_nftsOne,
} from '../../../services/myTradesService/__generated__/GetTokensFromId';

export const getDataForModal = async (data: GetTokensFromId) => {
  const dataForModal = await Promise.all(
    data.swaps[0].nftsTwo.map(async (token: GetTokensFromId_swaps_nftsOne) => {
      if (token.assetType === 'ERC1155') {
        const name = await axios
          .get(
            `https://api.covalenthq.com/v1/${process.env.REACT_APP_NETWORK_CHAINID}/tokens/${token.dapp.id}/nft_metadata/${token.tokenId}/?quote-currency=USD&format=JSON&key=ckey_${process.env.REACT_APP_COVALENT}`,
          )
          .then((res: any) => res.data.data.items[0].contract_name);

        return {
          name,
          type: 'ERC-1155',
          isApproved: 'false',
        };
      }
      return {
        name: token.dapp.name,
        type: `ERC-${token.assetType.substring(3)}`,
        isApproved: 'false',
      };
    }),
  );

  return dataForModal;
};
