import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { useLocation } from 'react-router-dom';
import { injected } from '../../helper/connector';

import Error from '../../pages/Error/Error';
import { reset } from '../../features/swapSlice';

function MetamaskProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { account, active: networkActive, error: networkError, activate: activateNetwork, error } = useWeb3React();
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const isWrongNetwork = error && error instanceof UnsupportedChainIdError;
  const location = useLocation();

  useEffect(() => {
    dispatch(reset());
  }, [account]);

  useEffect(() => {
    injected()
      .isAuthorized()
      .then((isAuthorized: boolean) => {
        setLoaded(true);
        const isDisconnected = localStorage.getItem('disconnect');
        if (isAuthorized && !networkActive && !networkError && isDisconnected !== 'true') {
          activateNetwork(injected());
          localStorage.setItem('disconnect', 'false');
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [activateNetwork, networkActive, networkError]);

  if (isWrongNetwork && location.pathname !== '/faq' && location.pathname !== '/support') {
    return <Error />;
  }

  if (loaded) {
    return children;
  }
  return <></>;
}

export default MetamaskProvider;
