import { useEffect, useState, useRef } from 'react';
import { useImagekitFetch } from '../../../../hooks/useImagekitFetch';

import {
  TokenWrapper,
  TokenImage,
  TokenContent,
  TokenUpperContent,
  TokenTitle,
  TokenNumbering,
  TokenAmount,
  LoadingContainer,
  VerifyIcon,
} from '../../ReviewTrade.styles';

import { NFTInfoModal } from '../../../../components/index';
import Loading from '../../../../components/Loading/Loading';
import verifyCheck from '../../../../icons/verifiy-check.svg';
import noImage from '../../../../images/no-image.svg';

import { calculateModalPosition } from '../../../../utils/calculateModalPosition';

type ERC1155TokenType = {
  id: string;
  tokenId: any[];
  balance: any[];
  address: string;
  isWhitelisted: boolean;
  isActive?: boolean;
};

function ERC1155Token({ id, tokenId, balance, address, isActive, isWhitelisted }: ERC1155TokenType) {
  const ref = useRef<HTMLInputElement>(null);
  const { fetchMetaURIFallback } = useImagekitFetch();
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isShownModal, setIsShownModal] = useState(false);
  const [tokenName, setTokenName] = useState('');
  const [modalWidth, setModalWidth] = useState(0);
  const [modalPosition, setModalPosition] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isTitleHovered, setIsTitleHovered] = useState(false);

  const getModalWidth = () => {
    if (!ref.current) return;

    const newWidth = ref.current.clientWidth;
    setModalWidth(newWidth);
  };

  useEffect(() => {
    if (isActive && isTitleHovered) {
      const timing = setTimeout(() => {
        setIsShownModal(true);
      }, 400);
      return () => clearTimeout(timing);
    }
    setIsShownModal(false);
    setIsTitleHovered(false);
    setModalPosition('');
  }, [isActive, isTitleHovered]);

  useEffect(() => {
    fetchMetaURIFallback(address, tokenId[0]).then((data: any) => {
      setImage(data?.image ?? '');
      setName(data?.ERC1155Name ?? '');
      setTokenName(data?.name ?? '');
      setDescription(data?.description ?? '');
      setIsLoading(false);
    });

    getModalWidth();
    window.addEventListener('resize', getModalWidth);
  }, []);

  return (
    <TokenWrapper key={id} ref={ref}>
      <NFTInfoModal
        isShown={isShownModal}
        position={modalPosition}
        title={name}
        description={description || ''}
        address={address}
        tokenId={tokenId[0]}
        tokenStandard="ERC-721"
        isWhitelisted={isWhitelisted}
      />
      {isLoading ? (
        <LoadingContainer>
          <Loading isLoading />
        </LoadingContainer>
      ) : (
        <TokenImage className={image === '' ? 'no-image' : ''} src={image || noImage} />
      )}
      <TokenContent>
        <TokenUpperContent>
          <TokenTitle
            onMouseOver={(e) => {
              setTimeout(() => {
                const modalPosition = calculateModalPosition(e, modalWidth);
                setModalPosition(modalPosition);
                setIsTitleHovered(true);
              }, 100);
            }}
          >
            {name ? (name.length > 16 ? name?.slice(0, 16).concat('...') : name) : ''}{' '}
            {isWhitelisted ? <VerifyIcon src={verifyCheck} alt="" /> : <></>}
          </TokenTitle>
          <TokenAmount>X{balance}</TokenAmount>
        </TokenUpperContent>
        <TokenNumbering
          onMouseOver={(e) => {
            setTimeout(() => {
              const modalPosition = calculateModalPosition(e, modalWidth);
              setModalPosition(modalPosition);
              setIsTitleHovered(true);
            }, 100);
          }}
        >
          {tokenName ? (tokenName.length > 16 ? tokenName?.slice(0, 16).concat('...') : tokenName) : ''}
        </TokenNumbering>
      </TokenContent>
    </TokenWrapper>
  );
}

export default ERC1155Token;
