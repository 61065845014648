import styled from 'styled-components';

interface RefreshModalProps {
  isShow: boolean;
}

export const RefreshModalContainer = styled.div<RefreshModalProps>`
  display: flex;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear, background-color 0.2s;
  align-items: center;
  gap: 0.75rem;
  width: 500px;
  height: 70px;
  background: #ffffff;
  position: fixed;
  z-index: 99;
  bottom: 150px;
  right: 30px;
  border-radius: 20px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 1rem;

  ${(props) =>
    props.isShow
      ? `
          visibility: visible;
      opacity: 1;
          `
      : ''}

  .close-icon {
    overflow: hidden;
    position: relative;
    border: none;
    padding: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: transparent;
    color: #888888;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 15%;
      left: calc(50% - 0.0625em);
      width: 0.125em;
      height: 70%;
      border-radius: 0.125em;
      transform: rotate(45deg);
      background: currentcolor;
      content: '';
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
`;

export const RefreshModalText = styled.span`
  color: #183b56;
  font-weight: 600;
  font-size: 0.95rem;
  padding-right: 2rem;
`;

export const RefreshModalCheckImage = styled.img`
  width: 24px;
  height: 24px;
`;
