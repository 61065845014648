import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WalletNotConnectedText } from '../../components/WalletNotConnected/WalletNotConnected';
import { Container } from '../../globalStyles';
import { Chains } from '../../helper/connector';

declare let window: any;

function Error() {
  const chainHex = process.env.REACT_APP_CHAIN_HEX;
  const networkName = Chains[+`${process.env.REACT_APP_NETWORK_CHAINID}`];
  const navigate = useNavigate()

  useEffect(() => {
    window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainHex }],
    });
    navigate('/swap')
  }, []);

  return (
    <Container>
      <WalletNotConnectedText>You need to change your network to {networkName}</WalletNotConnectedText>
    </Container>
  );
}

export default Error;
