export const refreshMetadata = async (
  e: React.SyntheticEvent,
  id: string,
  address: string,
  setShowRefreshModal: Function,
  setIsRefreshError: Function,
) => {
  const chain = process.env.REACT_APP_NETWORK_NAME;
  setShowRefreshModal(false);

  e.stopPropagation();
  try {
    const response = await fetch(`${process.env.REACT_APP_REFRESH_API_LINK}`, {
      method: 'POST',
      headers: {
        'x-api-key': `${process.env.REACT_APP_REFRESH_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: `{
        "chainId": "${chain}",
        "tokens": [
          {
            "address": "${address}",
            "id": ${id}
          }
        ]
      }`,
    });

    setShowRefreshModal(true);

    response
      .json()
      .then(() => {
        if (response.status !== 200) {
          setIsRefreshError(true);
        }
      })
      .catch((e) => {
        setIsRefreshError(true);
      });
  } catch (e) {
    setIsRefreshError(true);
  }
};
