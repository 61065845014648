import { useMoralisWeb3Api } from 'react-moralis';
import { getCDNPreviewUrl, transformIPFS } from './funcs';
import noImage from '../images/no-image.svg';

export const useFetchMetaURIFallback = () => {
  const Web3Api = useMoralisWeb3Api();

  const fetchMetaURIFallback = async (address: string, id: string) => {
    const options: any = {
      address: address,
      token_id: id,
      chain: `${process.env.REACT_APP_CHAIN_HEX}`,
    };
    const tokenIdMetadata = await Web3Api.token.getTokenIdMetadata(options);

    if (!tokenIdMetadata.metadata) {
      return {
        name: '',
        image: noImage,
      };
    }

    const tokenMetadata = JSON.parse(tokenIdMetadata?.metadata.toString());
    const modifiedImage = transformIPFS(tokenMetadata.image);
    const image = getCDNPreviewUrl(modifiedImage ? modifiedImage : '', undefined);
    const tokenName = tokenIdMetadata.name;

    return {
      name: tokenMetadata.name,
      image: image,
      ERC1155Name: tokenName,
      description: tokenMetadata.description,
    };
  };

  return { fetchMetaURIFallback };
};

export const useImagekitFetch = () => {
  const { fetchMetaURIFallback } = useFetchMetaURIFallback();

  const fetchImagekit = (url: string, address: string, id: string) => {
    const response = fetch(url)
      .then((response) => response.json())
      .then((data: any) => {
        const modifiedImage = transformIPFS(data.image);
        const image = getCDNPreviewUrl(modifiedImage ? modifiedImage : '', undefined);

        return {
          image: image,
          name: data.name,
        };
      })
      .catch(async () => {
        const response = await fetchMetaURIFallback(address, id);
        return response;
      });

    return response;
  };
  return { fetchImagekit, fetchMetaURIFallback };
};
