import { useEffect } from 'react';
import { RefreshModalContainer, RefreshModalText, RefreshModalCheckImage } from './RefreshModal.styles';

import checkIcon from '../../../../icons/check-icon-green.svg';
import errorIcon from '../../../../icons/error-icon.svg';

const RefreshModal = ({
  isShow,
  setShowRefreshModal,
  isRefreshError,
}: {
  isShow: boolean;
  setShowRefreshModal: Function;
  isRefreshError: boolean;
}) => {
  return (
    <RefreshModalContainer isShow={isShow}>
      {isRefreshError ? (
        <>
          <RefreshModalCheckImage src={errorIcon} />
          <RefreshModalText>
            We've encountered an error while requesting metadata! Please try again after a minute...
          </RefreshModalText>
        </>
      ) : (
        <>
          <RefreshModalCheckImage src={checkIcon} />
          <RefreshModalText>We've queued this item for an update! Check back in a minute...</RefreshModalText>
        </>
      )}
      <button className="close-icon" onClick={() => setShowRefreshModal(false)}></button>
    </RefreshModalContainer>
  );
};

export default RefreshModal;
