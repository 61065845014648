import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Routes, Route, Navigate } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { ApolloProvider } from '@apollo/client';
import { MoralisProvider } from 'react-moralis';
import { apolloClient } from './app/graphql';
import { Swap, ConnectWallet, MyTrades, ReviewTrade, ViewTokens } from './pages';

import { Navbar, MetamaskProvider, MobileWarning } from './components';
import { SwapInputAddress, SwapStepThree } from './pages/Swap/components';

function App() {
  function getLibrary(provider: any) {
    return new Web3Provider(provider);
  }

  const [showAccountDropdown, setShowAccountDropdown] = useState<boolean>();
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  useEffect(() => {
    isMobile ? setShowMobileWarning(true) : setShowMobileWarning(false);
  }, []);

  const servelUrl = `${process.env.REACT_APP_MORALIS_SERVER_URL}`;
  const appId = `${process.env.REACT_APP_MORALIS_APP_ID}`;

  return (
    <ApolloProvider client={apolloClient}>
      <MoralisProvider serverUrl={servelUrl} appId={appId}>
        <Web3ReactProvider getLibrary={getLibrary}>
          {showMobileWarning ? <MobileWarning closeWarning={setShowMobileWarning} /> : <></>}
          <Navbar
            show={showAccountDropdown}
            onClickOutside={() => {
              setShowAccountDropdown(!showAccountDropdown);
            }}
          />
          <MetamaskProvider>
            <div className="app-wrapper">
              <Routes>
                <Route path="/" element={<Navigate to="/swap" replace />} />
                <Route path="/swap" element={<Swap secondStep={false} isView={false} />} />
                <Route path="/view" element={<SwapInputAddress isView />} />
                <Route path="/swap/step-two/address" element={<SwapInputAddress isView={false} />} />
                <Route path="/view/:id" element={<ViewTokens />} />
                <Route path="/swap/step-two/tokens/:id" element={<Swap secondStep />} />
                <Route path="/swap/user-one/edit-assets" element={<Swap editAssets="Back to Swap" />} />
                <Route path="/swap/user-two/edit-assets/:id" element={<Swap secondStep editAssets="Back to Swap" />} />
                <Route path="/swap/step-three" element={<SwapStepThree />} />
                <Route path="/my-trades" element={<MyTrades />} />
                <Route path="/review-trade/:id" element={<ReviewTrade />} />
                <Route path="/details/:id" element={<ReviewTrade details />} />
                <Route path="/connect" element={<ConnectWallet />} />
              </Routes>
            </div>
          </MetamaskProvider>
        </Web3ReactProvider>
      </MoralisProvider>
    </ApolloProvider>
  );
}

export default App;
