import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { ethers } from 'ethers';
import { VaultSwap__factory } from '../typechain';

export const useGetFee = () => {
  const { account } = useWeb3React();
  const [fee, setFee] = useState('');
  const [isGettingFee, setIsGettingFee] = useState(true);

  const getFee = async () => {
    if (!account) return;

    const provider = new ethers.providers.JsonRpcProvider(
      `https://${process.env.REACT_APP_NETWORK_NAME}.infura.io/v3/${process.env.REACT_APP_INFURA_API}`,
    );
    const signer = provider.getSigner(account);
    const batchSwapContract = VaultSwap__factory.connect(`${process.env.REACT_APP_BATCH_SWAP_CONTRACT}`, signer);
    const tx = await batchSwapContract.getWeiPayValueAmount();
    setFee(tx.toString());
    setIsGettingFee(false);
  };

  useEffect(() => {
    if (!account) return;

    setIsGettingFee(true);

    getFee();
  }, [account]);

  return { fee, isGettingFee };
};
