import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ethers } from 'ethers';

import makeBlockie from 'ethereum-blockies-base64';

// @ts-ignore
import converter from 'hex2dec';
import ETHImage from '../assets/eth-network.svg';
import notAvailable from '../../../images/not-available.svg';

import TradeElementComp from './TradeElementNFT';

import {
  AccordionWrap,
  AccordionPreview,
  AccordionElement,
  ListName,
  Wrapper,
  UserIcoWrap,
  LinkText,
  Arrow,
  AssetsWrap,
  Assets,
  AssetTitle,
  Trade,
  TradeElement,
  ImageWrap,
  PrimaryBtn,
  AccordionButtonWrapper,
  AccordionCopyButton,
  TransactionHashWrapper,
  TopSpace,
} from './Accordion.styles';

interface IAccordion {
  date?: string;
  tradeId: string;
  account: string;
  status: string;
  yourETH: string;
  counterpartETH: string;
  createHash?: string;
  cancelHash?: string;
  closeHash?: string;
  yourAssets: any;
  counterpartAssets: any;
  outcomingTrade: boolean;
  setCancelModal: Function;
  setTradeId: Function;
}

const Accordion: FC<IAccordion> = ({
  date,
  tradeId,
  account,
  status,
  yourETH,
  counterpartETH,
  createHash,
  closeHash,
  cancelHash,
  yourAssets,
  counterpartAssets,
  outcomingTrade,
  setCancelModal,
  setTradeId,
}) => {
  const [active, setActive] = useState('');
  const [isCopied, setIsCopied] = useState('Copy');

  const navigate = useNavigate();

  const openModal = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    document.body.classList.add('no-scroll');
    setTradeId(tradeId);
    setCancelModal(true);
  };

  const copyAddress = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(account);
    setIsCopied('Copied');
    setTimeout(() => {
      setIsCopied('Copy');
    }, 1400);
  };

  const openTransaction = (e: React.SyntheticEvent, hash: string) => {
    e.stopPropagation();
    window.open(`https://${process.env.REACT_APP_ETHERSCAN_URL}/tx/${hash}`, '_blank');
  };

  return (
    <AccordionWrap
      className={active}
      key={tradeId}
      onClick={() => {
        active === '' ? setActive('active') : setActive('');
      }}
    >
      <AccordionPreview>
        <AccordionElement minW="60px">
          <ListName>Trade ID</ListName>
          <span>{converter.hexToDec(tradeId)}</span>
        </AccordionElement>
        <AccordionElement minW="160px">
          <ListName>Date</ListName>
          <span>{date}</span>
        </AccordionElement>
        <AccordionElement w="10%" minW="130px">
          {status !== 'Canceled' ? (
            <>
              <ListName>Counterparty</ListName>
              <Wrapper disp="flex" alignItems="center" gap="4px">
                <UserIcoWrap>
                  <img src={makeBlockie(account)} alt="" />
                </UserIcoWrap>
                <span>{account.slice(0, 5).concat('...') + account.slice(39, 42).concat()}</span>
                <AccordionButtonWrapper onClick={copyAddress}>
                  <div className="copyWindow">
                    <span>{isCopied}</span>
                  </div>
                  <AccordionCopyButton />
                </AccordionButtonWrapper>
              </Wrapper>
            </>
          ) : (
            <></>
          )}
        </AccordionElement>
        <AccordionElement w="10%">
          <ListName>Status</ListName>
          <span>{status}</span>
        </AccordionElement>
        {status === 'Completed' && closeHash && (
          <PrimaryBtn onClick={() => navigate(`/details/${parseInt(tradeId, 16)}`)}>Details</PrimaryBtn>
        )}
        {status === 'Pending' && outcomingTrade && <PrimaryBtn onClick={openModal}>Cancel Trade</PrimaryBtn>}
        {status === 'Pending' && !outcomingTrade && (
          <PrimaryBtn onClick={() => navigate(`/review-trade/${parseInt(tradeId, 16)}`)}>Review Trade</PrimaryBtn>
        )}
        <Arrow className={active} />
      </AccordionPreview>
      {/* Trade Assets */}
      <AssetsWrap className={active}>
        <Assets br="1px solid #ECF1F9" padd="10px 0">
          {outcomingTrade ? (
            <TransactionHashWrapper>
              <ListName>Transaction hash</ListName>
              <LinkText onClick={(e) => openTransaction(e, createHash!)}>
                {createHash?.slice(0, 12).concat('...') +
                  createHash!.slice(createHash!.length - 12, createHash!.length).concat()}
              </LinkText>
            </TransactionHashWrapper>
          ) : status === 'Completed' ? (
            <TransactionHashWrapper>
              <ListName>Transaction hash</ListName>
              <LinkText onClick={(e) => openTransaction(e, closeHash!)}>
                {closeHash?.slice(0, 12).concat('...') +
                  closeHash!.slice(closeHash!.length - 12, closeHash!.length).concat()}
              </LinkText>
            </TransactionHashWrapper>
          ) : status === 'Canceled' ? (
            <TransactionHashWrapper>
              <ListName>Transaction hash</ListName>
              <LinkText onClick={(e) => openTransaction(e, cancelHash!)}>
                {cancelHash?.slice(0, 12).concat('...') +
                  cancelHash!.slice(cancelHash!.length - 12, cancelHash!.length).concat()}
              </LinkText>
            </TransactionHashWrapper>
          ) : (
            <TopSpace />
          )}
          <AssetTitle>Your Assets</AssetTitle>
          <Trade>
            {yourAssets?.map((asset: any) => {
              if (asset.assetType === 'ERC20' && asset.dapp.decimals) {
                return (
                  <TradeElement key={asset.id}>
                    <ImageWrap>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/token-icons/${asset.dapp.symbol?.toLowerCase()}.svg`}
                        alt="nft-trade"
                        onError={(e) => {
                          e.currentTarget.src = notAvailable;
                        }}
                      />
                    </ImageWrap>
                    <span>
                      {ethers.utils.formatUnits(asset.balance[0], asset.dapp.decimals)} {asset.dapp.symbol}
                    </span>
                  </TradeElement>
                );
              }
              if (asset.metaURI[0] && asset.metaURI[0] !== 'no-metadata') {
                return <TradeElementComp id={asset.tokenId[0]} metaURI={asset.metaURI[0]} address={asset.dapp.id} />;
              }
            })}
            {yourETH !== '0' ? (
              <TradeElement key="eth">
                <ImageWrap>
                  <img src={ETHImage} alt="" />
                </ImageWrap>
                <span>
                  {ethers.utils.formatEther(yourETH)}
                  ETH
                </span>
              </TradeElement>
            ) : (
              <></>
            )}
          </Trade>
        </Assets>
        <Assets>
          {!outcomingTrade ? (
            <TransactionHashWrapper>
              <ListName>Transaction hash</ListName>
              <LinkText onClick={(e) => openTransaction(e, createHash!)}>
                {createHash?.slice(0, 12).concat('...') +
                  createHash!.slice(createHash!.length - 12, createHash!.length).concat()}
              </LinkText>
            </TransactionHashWrapper>
          ) : status === 'Completed' ? (
            <TransactionHashWrapper>
              <ListName>Transaction hash</ListName>
              <LinkText onClick={(e) => openTransaction(e, closeHash!)}>
                {closeHash?.slice(0, 12).concat('...') +
                  closeHash!.slice(closeHash!.length - 12, closeHash!.length).concat()}
              </LinkText>
            </TransactionHashWrapper>
          ) : status === 'Canceled' ? (
            <TransactionHashWrapper>
              <ListName>Transaction hash</ListName>
              <LinkText onClick={(e) => openTransaction(e, cancelHash!)}>
                {cancelHash?.slice(0, 12).concat('...') +
                  cancelHash!.slice(cancelHash!.length - 12, cancelHash!.length).concat()}
              </LinkText>
            </TransactionHashWrapper>
          ) : (
            <TopSpace />
          )}
          <AssetTitle>Counterparty Assets</AssetTitle>
          <Trade>
            {counterpartAssets?.map((asset: any) => {
              if (asset.assetType === 'ERC20' && asset.dapp.decimals) {
                return (
                  <TradeElement key={asset.id}>
                    <ImageWrap>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/token-icons/${asset.dapp.symbol.toLowerCase()}.svg`}
                        alt="nft-trade"
                        onError={(e) => {
                          e.currentTarget.src = notAvailable;
                        }}
                      />
                    </ImageWrap>
                    <span>
                      {ethers.utils.formatUnits(asset.balance[0], asset.dapp.decimals)} {asset.dapp.symbol}
                    </span>
                  </TradeElement>
                );
              }
              if (asset.metaURI[0] !== 'no-metadata') {
                return <TradeElementComp id={asset.tokenId[0]} metaURI={asset.metaURI[0]} address={asset.dapp.id} />;
              }
            })}
            {counterpartETH !== '0' ? (
              <TradeElement key="eth">
                <ImageWrap>
                  <img src={ETHImage} alt="" />
                </ImageWrap>
                <span>
                  {ethers.utils.formatEther(counterpartETH)}
                  ETH
                </span>
              </TradeElement>
            ) : (
              <></>
            )}
          </Trade>
        </Assets>
      </AssetsWrap>
    </AccordionWrap>
  );
};

export default Accordion;
