import { FC, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useGetUserTrades, AllTrades } from '../../hooks/useGetUserTrades';

import { useGetFee } from '../../hooks/useGetFee';

import { Container } from '../../globalStyles';

import { SwapButtonsWrapper } from '../Swap/components/SwapStepOne/SwapStepOne.styles';
import {
  PrimaryHeading,
  SwapLoading,
  MyTradesHeader,
  SearchWrapper,
  SearchImg,
  MyTradesInput,
  NothingsFound,
} from './MyTrades.styles';
import { TokenStyles } from '../Swap/components/TokenButton/TokenButton';
import Accordion from './page-components/Accordion';

import { Loading, WalletNotConnected } from '../../components';

import { getUserTradesCount } from '../../utils/gerUserTradesCount';
import CancelTradeModal from '../../components/CancelTradeModal/CancelTradeModal';

import SearchIcon from '../../icons/search-icon.svg';
import RefreshIcon from '../../icons/refresh-icon.svg';
import { RefreshButton } from '../ViewTokens/ViewTokens';

type TabType = {
  name: string;
  count: number;
  isSelected: boolean;
};

const MyTrades: FC = () => {
  const { account } = useWeb3React();
  const [selected, setSelected] = useState('All');
  const [trades, setTrades] = useState<AllTrades>([]);
  const { data, isLoading, isRefresh, setIsRefresh } = useGetUserTrades();
  const { fee } = useGetFee();
  const [tabMenu, setTabMenu] = useState<TabType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tradeId, setTradeId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const getFormatedDate = (date: string) => {
    const formateDate = new Date(+date * 1000)
      .toLocaleDateString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .toLowerCase();

    return formateDate;
  };

  const filteredTrades = () => {
    if (!data) return;

    const trades = data.filter((element) => {
      switch (selected) {
        case 'All':
          return true;
        case 'Completed':
          if (element.status === 'Completed') {
            return true;
          }
          break;
        case 'Pending':
          if (element.status === 'Pending') {
            return true;
          }
          break;
        case 'Canceled':
          if (element.status === 'Canceled') {
            return true;
          }
          break;
      }
      return null;
    });

    setTrades(trades);
  };

  useEffect(() => {
    filteredTrades();
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (!data) return;

    const tabs = getUserTradesCount(data);

    setTabMenu(tabs);
    filteredTrades();
    // eslint-disable-next-line
  }, [data]);

  return (
    <Container className="margin-bottom">
      {!account ? (
        <WalletNotConnected />
      ) : (
        <>
          {isLoading ? (
            <SwapLoading>
              <Loading isLoading />
            </SwapLoading>
          ) : (
            <>
              {isModalOpen ? <CancelTradeModal closeModal={setIsModalOpen} tradeId={tradeId} fee={fee} /> : <></>}
              <PrimaryHeading mt="32px">
                Trade List{' '}
                <RefreshButton
                  src={RefreshIcon}
                  onClick={() => {
                    setIsRefresh(!isRefresh);
                  }}
                  alt="refresh button"
                />
              </PrimaryHeading>
              <MyTradesHeader>
                <SwapButtonsWrapper>
                  {tabMenu.map((tab: TabType, key: number) => (
                    <TokenStyles
                      className={tab.name === selected ? 'selected' : ''}
                      onClick={() => {
                        setSelected(tab.name);
                      }}
                      key={key}
                    >
                      {tab.name} ({tab.count})
                    </TokenStyles>
                  ))}
                </SwapButtonsWrapper>
                <SearchWrapper>
                  <SearchImg src={SearchIcon} />
                  <MyTradesInput
                    type="text"
                    placeholder="Search"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </SearchWrapper>
              </MyTradesHeader>
              {trades.length ? <NothingsFound>Nothing's found</NothingsFound> : <></>}
              {trades
                .filter((trade) => {
                  if (searchTerm === '') return trade;
                  if (
                    trade.addressOne.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    trade.addressTwo.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return trade;
                  }

                  return null;
                })
                .map((trade) =>
                  trade.incoming ? (
                    <Accordion
                      key={trade.id}
                      date={getFormatedDate(trade.swapStart)}
                      tradeId={trade.id}
                      account={trade.addressOne}
                      status={trade.status}
                      yourETH={trade.valueTwo}
                      counterpartETH={trade.valueOne}
                      yourAssets={trade.nftsTwo}
                      counterpartAssets={trade.nftsOne}
                      createHash={trade.createAction}
                      closeHash={trade.closeAction}
                      cancelHash={trade.cancelAction}
                      outcomingTrade={false}
                      setCancelModal={setIsModalOpen}
                      setTradeId={setTradeId}
                    />
                  ) : (
                    <Accordion
                      key={trade.id}
                      date={getFormatedDate(trade.swapStart)}
                      tradeId={trade.id}
                      account={trade.addressTwo}
                      status={trade.status}
                      yourETH={trade.valueOne}
                      counterpartETH={trade.valueTwo}
                      yourAssets={trade.nftsOne}
                      counterpartAssets={trade.nftsTwo}
                      createHash={trade.createAction}
                      closeHash={trade.closeAction}
                      cancelHash={trade.cancelAction}
                      outcomingTrade
                      setCancelModal={setIsModalOpen}
                      setTradeId={setTradeId}
                    />
                  ),
                )}
              {!trades.length && (
                <NothingsFound>
                  You don't have any {selected.toLowerCase() === 'all' ? '' : selected.toLowerCase()} swaps
                </NothingsFound>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default MyTrades;
