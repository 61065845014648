import { useEffect, useState } from 'react';
import { useImagekitFetch } from '../../../hooks/useImagekitFetch';

import { TradeElement, ImageWrap } from './Accordion.styles';

import LoaderForFetchNfts from './LoaderForTradesNft';

type TradeElementType = {
  id: string;
  metaURI: string;
  address: string;
};

function TradeElementComp({ id, metaURI, address }: TradeElementType) {
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [isLoader, setIsLoader] = useState<boolean>(true);
  const { fetchImagekit } = useImagekitFetch();

  useEffect(() => {
    fetchImagekit(metaURI, address, id).then((data: any) => {
      setImage(data?.image ?? '');
      setName(data?.name ?? '');
      setIsLoader(false);
    });
  }, []);

  return (
    <TradeElement key={id}>
      <ImageWrap>
        {isLoader ? <LoaderForFetchNfts isLoading={isLoader} /> : <img src={image} alt="nft-trade" />}
      </ImageWrap>
      <span>{name ? name.slice(0, 7).concat('...') : ''}</span>
    </TradeElement>
  );
}

export default TradeElementComp;
